import React from "react";
import { Tooltip, Zoom } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Settings from "../../settings.json";
import { Logo } from "./Logo";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    svg: {
        width: "40px",
        height: "40px",
        position: "fixed",
        top: theme.spacing(6),
        left: theme.spacing(6),
        boxShadow:
            "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
        borderRadius: "50%",
        zIndex: 1000,
    },
}));

export const LogoLink = () => {
    const classes = useStyles();

    return (
        <Tooltip
            title={Settings.basics.name}
            placement="right"
            TransitionComponent={Zoom}
        >
            <Link
                variant="h6"
                to="/"
                underline="none"
                color="inherit"
                nowrap="true"
                className={classes.svg}
            >
                <Logo />
            </Link>
        </Tooltip>
    );
};
