import { ApiPromise, WsProvider } from '@polkadot/api';
import { BN_MILLION } from '@polkadot/util';

const STASH_TO_IDENTITY = {
    '13jagnVH4vS6a5XD1xixGA4zUxgjkZ9h8dbQDVCDZYR6rS9m': 'digitalghost.xyz/dot02',
    '14bGpFntaPUgoSNdXeU99ddPH8RvHfZRic1dCcQBwnNuJwwr': 'digitalghost.xyz/dot01',
    'Dtxgg4SpguAtcjZwsrGGy3zWVuZEYCa45q1qXu9EcV2uwY3': 'digitalghost.xyz/ksm06',
    'HEYzAi8mdAFmJeP1wCP32giBKpA7yAAPqPCqR6qNzz7heEM': 'digitalghost.xyz/ksm05',
    'J7LxQM11jrFNLoB7ucfLGE814Jg3emnY2uJW3Hii1cv136G': 'digitalghost.xyz/ksm04',
    'GUTWXeDJ9CDvHKo6hmKQtgsppXgvxyygUWJBak4DRqa3pyB': 'digitalghost.xyz/ksm03',
    'HnXarAhRKBsFfiiYJNZZf24BurrH8WkvWMguVt8dw5sxFah': 'digitalghost.xyz/ksm02',
    'FkqSqeRVr4VceTBKFXzwdSAjmB1QuhhVTdN1CXhRFvtPRZa': 'digitalghost.xyz/ksm01',
};
const POLKADOT_ENDPOINT = 'wss://rpc-polkadot.luckyfriday.io';
const KUSAMA_ENDPOINT = 'wss://rpc-kusama.luckyfriday.io';
const TOKENOMICS_PARAMS = {
    auctionAdjust: 0,
    auctionMax: 0,
    // https://github.com/paritytech/polkadot/blob/816cb64ea16102c6c79f6be2a917d832d98df757/runtime/kusama/src/lib.rs#L534
    falloff: 0.05,
    // https://github.com/paritytech/polkadot/blob/816cb64ea16102c6c79f6be2a917d832d98df757/runtime/kusama/src/lib.rs#L523
    maxInflation: 0.1,
    minInflation: 0.025,
    stakeTarget: 0.5
};
const POLKADOT_PARAMS = { ...TOKENOMICS_PARAMS, stakeTarget: 0.75 };
const KUSAMA_PARAMS = { ...TOKENOMICS_PARAMS, auctionAdjust: (0.3 / 60), auctionMax: 60, stakeTarget: 0.75 };

const network = (stash) => stash.startsWith('1') ? 'polkadot' : 'kusama';
const to_human_commission = (commission) => `${(commission.toNumber() / 1e7).toFixed(2)}%`;
const to_human_currency = (amount, symbol) => {
  const units = ['', 'K', 'M', 'B', 'T'];
  const divider = symbol === 'DOT' ? 1e10 : 1e12;
  const a = amount / divider;
  if (a < 10000) {
    return a.toFixed(0);
  }
  for (let i = 0, a = amount / divider; i < units.length; i++, a /= 1000) {
    if (a < 1000) {
      return `${a.toFixed(2)}${units[i]}`;
    }
  }
};

async function batchCommon(api, params) {
    const activeEra = (await api.query.staking.activeEra()).unwrap().index;
    const [
        activeTotalStake,
        validatorCount,
        validators,
        totalIssuance,
        auctionCount,
    ] = await Promise.all([
        api.query.staking.erasTotalStake(activeEra),
        api.query.staking.validatorCount(),
        api.query.session.validators(),
        api.query.balances.totalIssuance(),
        api.query.auctions.auctionCounter(),
    ]);

    const common = {
        activeEra,
        activeTotalStake: +activeTotalStake,
        activeValidatorCount: +validatorCount,
        activeValidators: validators.map((v) => v.toHuman()),
        stakingReturnRate: stakingReturn(params, activeTotalStake, totalIssuance, auctionCount),
    };

    const overviews = await Promise.all(
        common.activeValidators.map((stash) => api.query.staking.erasStakersOverview(activeEra, stash)),
    );
    const activeLowestStake = overviews
        .map((overview) => overview.unwrapOrDefault().total)
        .reduce((min, stake) => Math.min(min, stake), Infinity);
    common.activeLowestStake = activeLowestStake;
    return common;
}

function stakingReturn(params, totalStaked, totalIssuance, auctionCount) {
    const { auctionAdjust, auctionMax, falloff, maxInflation, minInflation, stakeTarget } = params;
    const stakedRatio = totalStaked.isZero() || totalIssuance.isZero()
        ? 0
        : totalStaked.mul(BN_MILLION).div(totalIssuance).toNumber() / BN_MILLION.toNumber();
    // https://github.com/paritytech/polkadot/blob/816cb64ea16102c6c79f6be2a917d832d98df757/runtime/kusama/src/lib.rs#L531
    const idealStake = stakeTarget - (Math.min(auctionMax, auctionCount.toNumber()) * auctionAdjust);
    const idealInterest = maxInflation / idealStake;
    // https://github.com/paritytech/substrate/blob/0ba251c9388452c879bfcca425ada66f1f9bc802/frame/staking/reward-fn/src/lib.rs#L28-L54
    const inflation = 100 * (minInflation + (
        stakedRatio <= idealStake
            ? (stakedRatio * (idealInterest - (minInflation / idealStake)))
            : (((idealInterest * idealStake) - minInflation) * Math.pow(2, (idealStake - stakedRatio) / falloff))
    ));
    return stakedRatio > 0 ? inflation / stakedRatio : 0;
}

async function lastPayoutTime(stash) {
    const endpoint = `https://${network(stash)}.webapi.subscan.io/api/v2/scan/account/reward_slash`;
    try {
        const res = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                address: stash,
                row: 1,
                page: 0,
                category: 'Reward',
                is_stash: true,
                claimed_filter: 'claimed',
            }),
        });
        const data = (await res.json()).data.list;
        if (!data) { return ''; }

        const timestamp = data[0].block_timestamp;
        const timeDiff = Date.now() / 1000 - timestamp;
        let rendered = '';
        if (timeDiff < 120) {
            rendered = `just now`;
        } else if (timeDiff < 7200) {
            const diffHours = Math.floor(timeDiff / 60);
            rendered = `${diffHours} minutes ago`;
        } else {
            const diffHours = Math.floor(timeDiff / 3600);
            rendered = `${diffHours} hours ago`;
        }
        return rendered;
    } catch (err) {
        console.error('Payout', err);
        return '';
    }
}

export function validatorsPlaceholder() {
    const stashes = Object.keys(STASH_TO_IDENTITY);
    return stashes.map((stash) => ({
        endpoint: network(stash) === 'polkadot' ? POLKADOT_ENDPOINT : KUSAMA_ENDPOINT,
        stash,
        identity: STASH_TO_IDENTITY[stash],
        active: false,
        commission: '',
        nominators: 0,
        stake: 0,
        lowest: 0,
        selfVsLowest: '',
        apy: '',
        payout: '',
        recentEras: [],
    }));
}

export async function validatorsData() {
    console.time('API establishment');
    const [
        polkadotApi,
        kusamaApi,
    ] = await Promise.all([
        ApiPromise.create({ provider: new WsProvider(POLKADOT_ENDPOINT, 1000 * 5) }),
        ApiPromise.create({ provider: new WsProvider(KUSAMA_ENDPOINT, 1000 * 5) }),
    ]);
    console.timeEnd('API establishment');

    console.time('Fetching blockchain info');
    const [
        polkadot,
        kusama,
    ] = await Promise.all([
        batchCommon(polkadotApi, POLKADOT_PARAMS),
        batchCommon(kusamaApi, KUSAMA_PARAMS),
    ]);
    console.timeEnd('Fetching blockchain info');

    console.time('Fetching validators info');
    const stashes = Object.keys(STASH_TO_IDENTITY);
    const validators = await Promise.all(stashes.map(async (stash) => {
        const [endpoint, api, common, symbol] = network(stash) === 'polkadot'
            ? [POLKADOT_ENDPOINT, polkadotApi, polkadot, 'DOT']
            : [KUSAMA_ENDPOINT, kusamaApi, kusama, 'KSM'];

        const {
            activeEra,
            activeLowestStake,
            activeTotalStake,
            activeValidatorCount,
            activeValidators,
            stakingReturnRate,
        } = common;

        const [
            validator,
            payout,
            stakerOverviews,
        ] = await Promise.all([
            api.query.staking.validators(stash),
            lastPayoutTime(stash),
            Promise.all(Array.from({ length: 17 }, async (_, i) => {
                const era = activeEra - i;
                const stakerOverview = await api.query.staking.erasStakersOverview(era, stash);
                const staker = stakerOverview.unwrapOrDefault();
                const stake = +staker.total;
                const active = stake > 0;
                const nominators = +staker.nominatorCount;
                return {
                    era,
                    stake,
                    active,
                    nominators,
                };
            })),
        ]);

        const avgStake = activeTotalStake / activeValidatorCount;
        const stake = stakerOverviews[0].stake;
        const validatorCommission = validator.commission.toNumber() / 1e9;
        const validatorApy = stakingReturnRate * avgStake / stake * (1 - validatorCommission);

        return {
            endpoint,
            stash,
            identity: STASH_TO_IDENTITY[stash],
            active: activeValidators.includes(stash),
            commission: to_human_commission(validator.commission),
            nominators: stakerOverviews[0].nominators,
            stake,
            lowest: activeLowestStake,
            selfVsLowest: `${to_human_currency(stake, symbol)} / ${to_human_currency(activeLowestStake, symbol)} ${symbol}`,
            apy: validatorApy === Infinity ? '-' : `${validatorApy.toFixed(2)}%`,
            payout: payout ? payout : '-',
            recentEras: stakerOverviews.slice(1).reverse().map((overview) => {
                overview.stake = `${to_human_currency(overview.stake, symbol)} ${symbol}`;
                return overview;
            }),
        };
    }));
    console.timeEnd('Fetching validators info');
    return validators;
}
