import React from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { HelmetMeta } from "./HelmetMeta";
import { ThemeProvider } from "../components/theme/ThemeProvider";
import { CssBaseline } from "@material-ui/core";
import DisplacementSphere from '../components/background/DisplacementSphere';
import { LogoLink } from '../components/logo/LogoLink';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeToggle } from '../components/theme/ThemeToggle';
import { FooterText } from '../components/footer/FooterText';
import { SocialIcons } from '../components/content/SocialIcons';
import { SpeedDials } from '../components/speedDial/SpeedDial';
import { Home, Validators } from '../components/content/Content';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
}));

export const App = () => {
    const classes = useStyles();
    return (
        <ThemeProvider>
            <CssBaseline />
            <Router>
                <HelmetMeta />
                <div className={classes.root}>
                    <DisplacementSphere />
                    <LogoLink />
                    <Switch>
                        <Route path="/validators" component={Validators}/>
                        <Route path="/dot02" component={Validators}/>
                        <Route path="/dot01" component={Validators}/>
                        <Route path="/dot" component={Validators}/>
                        <Route path="/ksm06" component={Validators}/>
                        <Route path="/ksm05" component={Validators}/>
                        <Route path="/ksm04" component={Validators}/>
                        <Route path="/ksm03" component={Validators}/>
                        <Route path="/ksm02" component={Validators}/>
                        <Route path="/ksm01" component={Validators}/>
                        <Route path="/ksm" component={Validators}/>
                        <Route path="*" component={Home} />
                    </Switch>
                    <ThemeToggle />
                    <Hidden smDown>
                    <SocialIcons />
                    </Hidden>
                    <Hidden mdUp>
                    <SpeedDials />
                    </Hidden>
                    <FooterText />
                </div>
            </Router>
        </ThemeProvider>
    );
};
