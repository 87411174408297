import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link, Box, useMediaQuery } from '@material-ui/core';
import { HeartIcon } from '../content/SponsorButton';
import { ThemeContext } from '../theme/ThemeProvider';

const useStyles = makeStyles((theme) => ({
  footerText: {
    position: 'fixed',
    bottom: theme.spacing(6),
    left: theme.spacing(6),
    display: 'flex',
    alignItems: 'center',
    zIndex: 1000,
    backgroundColor: theme.palette.type === 'dark' ? 'rgba(65, 65, 65, 0.4)' : 'rgba(225, 225, 225, 0.4)',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(2),
    backdropFilter: 'blur(4px)',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    transition: 'all 0.5s ease',
  },
  heartIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  }
}));

export const FooterText = () => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles({ theme });
  const isMobile = useMediaQuery('(max-width:600px)');
  const [showLinks, setShowLinks] = useState(false);

  const toggleLinks = () => {
    if (isMobile) {
      setShowLinks(!showLinks);
    }
  };

  return (
    <div className={classes.footerText}>
      <div onClick={toggleLinks} className={classes.heartIcon}>
        <HeartIcon />
      </div>
      {(!isMobile || showLinks) && (
        <Box display="flex" alignItems="center" ml={1}>
          <Typography variant='body1'>
            <Link
              className={classes.link}
              href='https://polkadot.js.org/apps/?filter=digitalghost.xyz&rpc=wss%3A%2F%2Frpc-polkadot.luckyfriday.io#/staking/targets'
              target='_blank'
              rel='noopener noreferrer'
            >{'Nominate on Polkadot'}</Link>
            <span style={{ margin: '0 8px' }}>or</span>
            <Link
              className={classes.link}
              href='https://polkadot.js.org/apps/?filter=digitalghost.xyz&rpc=wss%3A%2F%2Frpc-kusama.luckyfriday.io#/staking/targets'
              target='_blank'
              rel='noopener noreferrer'
            >{'Kusama'}</Link>
          </Typography>
        </Box>
      )}
    </div>
  );
};
