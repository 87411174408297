import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Typography, Container, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { TextDecrypt } from "./TextDecrypt";
import ValidatorCard from "./ValidatorCard";
import { validatorsPlaceholder, validatorsData } from "./Validators";
import { ThemeContext } from "../theme/ThemeProvider";

const useStyles = makeStyles((theme) => ({
    main: {
        marginTop: "auto",
        marginBottom: "auto",
        "@media (max-width: 768px)": {
            marginLeft: theme.spacing(4),
        },
    },
    alignCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

    },
    link: {
        color: 'inherit',
        textDecoration: 'underline',
        '&:hover': {
            color: theme.palette.primary.main,
        },
        transition: 'all 0.5s ease',
    },
    address: {
        fontSize: '1.125rem',
        wordWrap: 'break-word',
        marginBottom: theme.spacing(3),
    },
    dividerContainer: {
        position: 'relative',
        textAlign: 'center',
        margin: '60px 0 20px 0',
        width: '100%',
    },
    dividerLine: {
        position: 'absolute',
        top: '50%',
        left: '0',
        right: '0',
        height: '1px',
        backgroundColor: ({ theme: currentTheme }) =>
            currentTheme === 'light'
                ? 'rgba(0, 0, 0, 0.1)'
                : 'rgba(255, 255, 255, 0.1)',
        zIndex: 0,
    },
    dividerText: {
        position: 'relative',
        padding: '0 16px',
        fontSize: '0.75rem',
        fontFamily: '"Roboto Mono", monospace',
        color: ({ theme: currentTheme }) =>
            currentTheme === 'light'
                ? 'rgba(255, 255, 255, 0.6)'
                : 'rgba(0, 0, 0, 0.6)',
        backgroundColor: ({ theme: currentTheme }) =>
            currentTheme === 'light'
                ? 'rgba(17, 17, 17, 0.6)'
                : 'rgba(250, 250, 250, 0.6)',
        zIndex: 1,
    },
}));

export const Home = () => {
    const classes = useStyles();

    return (
        <Container component="main" className={`${classes.main}`} maxWidth="sm">
            <Typography variant="h2" component="h1" gutterBottom>
                <TextDecrypt text="Hey, we're" />
                <TextDecrypt text="DigitalGhost" />
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                <TextDecrypt text="We keep Polkadot/Kusama secure" interval={30} />
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom>
                <Link to="/validators" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/dot02" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/dot01" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/dot" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/ksm06" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/ksm05" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/ksm04" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/ksm03" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/ksm02" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/ksm01" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
            <Typography variant="h5" component="h2" gutterBottom style={{ display: 'none' }}>
                <Link to="/ksm" className={classes.link}>
                    <TextDecrypt text="Check out our validators" interval={30} />
                </Link>
            </Typography>
        </Container>
    );
};

export const Validators = () => {
    const { theme } = useContext(ThemeContext);
    const classes = useStyles({ theme });

    const [loading, setLoading] = useState(true);
    const [validators, setValidators] = useState(validatorsPlaceholder());

    useEffect(() => {
        (async () => {
            setLoading(true);

            const cache = localStorage.getItem('validators-cache');
            if (cache) {
                const { validators, timestamp } = JSON.parse(cache);
                const cacheAge = Date.now() - timestamp;
                const oneMinute = 60 * 1000;
                if (cacheAge < oneMinute) {
                    setValidators(validators);
                    setLoading(false);
                    return;
                }
            }

            try {
                const validators = await validatorsData();
                setValidators(validators);
                setLoading(false);
                localStorage.setItem('validators-cache', JSON.stringify({
                    validators,
                    timestamp: Date.now()
                }));
            } catch (error) {
                console.error("Error fetching validator data:", error);
                setLoading(false);
            }
        })();
    }, []);

    return (
        <Container component="main" className={`${classes.main}`} maxWidth="lg">
        <>
            <div className={classes.dividerContainer}>
                <div className={classes.dividerLine} />
                <span className={classes.dividerText}>
                    POLKADOT
                </span>
            </div>

            <Grid container spacing={2} style={{ justifyContent: 'left' }}>
                {validators
                    .filter(validator => validator.stash.startsWith('1'))
                    .map((validator) => (
                        <Grid item xs={12} sm={6} md={4} key={validator.stash}>
                            <ValidatorCard loading={loading} {...validator} />
                        </Grid>
                    ))}
            </Grid>

            <div className={classes.dividerContainer}>
                <div className={classes.dividerLine} />
                <span className={classes.dividerText}>
                    KUSAMA
                </span>
            </div>

            <Grid container spacing={2} style={{ marginBottom: '160px', justifyContent: 'left' }}>
                {validators
                    .filter(validator => !validator.stash.startsWith('1'))
                    .map((validator) => (
                        <Grid item xs={12} sm={6} md={4} key={validator.stash}>
                            <ValidatorCard loading={loading} {...validator} />
                        </Grid>
                    ))}
            </Grid>
        </>
        </Container>
    );
}
