import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CircularProgress, Typography, Box, IconButton, Snackbar, Tooltip, Link } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ThemeContext } from '../theme/ThemeProvider';
import { primary } from '../theme/Themes';

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    width: '100%',
    minWidth: '320px',
    maxWidth: '420px',
    margin: '0 auto',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
    background: ({ theme: currentTheme }) =>
      currentTheme === 'light'
        ? 'rgba(250, 250, 250, 0.4)'
        : 'rgba(17, 17, 17, 0.4)',
    backdropFilter: 'blur(10px)',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    transition: 'all 0.3s ease',
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  identity: {
    fontFamily: '"Roboto Mono", monospace',
    marginRight: theme.spacing(1),
    color: 'inherit',
    textDecoration: 'underline',
  },
  stash: {
    fontFamily: '"Roboto Mono", monospace',
    fontSize: '0.675rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  copyButton: {
    padding: theme.spacing(0.5),
    color: ({ theme: currentTheme }) =>
      currentTheme === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.5)',
    '&:hover': {
      color: ({ theme: currentTheme }) =>
        currentTheme === 'light' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(255, 255, 255, 0.8)',
    },
    backgroundColor: ({ theme: currentTheme }) =>
      currentTheme === 'light' ? 'rgba(0, 0, 0, 0.04)' : 'rgba(255, 255, 255, 0.04)',
    boxShadow: 'none',
  },
  stashContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    overflow: 'hidden',
    marginBottom: theme.spacing(1),
  },
  statusUnknown: {
    display: 'inline-block',
    background: '#808080',
    color: 'white',
    padding: '3px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
  statusActive: {
    display: 'inline-block',
    background: '#4CAF50',
    color: 'white',
    padding: '3px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
  statusWaiting: {
    display: 'inline-block',
    background: '#FF8904',
    color: 'white',
    padding: '3px 8px',
    borderRadius: '4px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
  },
  dataRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
  },
  label: {
    fontFamily: '"Roboto Mono", monospace',
    fontWeight: '500',
    fontSize: '0.9rem',
    color: ({ theme: currentTheme }) =>
      currentTheme === 'light' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(255, 255, 255, 0.6)',
  },
  helpIcon: {
    fontSize: '0.9rem',
    marginLeft: theme.spacing(0.5),
    color: ({ theme: currentTheme }) =>
      currentTheme === 'light' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(255, 255, 255, 0.4)',
    verticalAlign: 'middle',
  },
  snackbar: {
    '& .MuiSnackbarContent-root': {
      minWidth: 'auto',
      padding: theme.spacing(0.5, 2),
    },
    '& .MuiSnackbarContent-message': {
      textAlign: 'center',
      width: '100%',
      fontFamily: '"Roboto Mono", monospace',
      fontSize: '0.8rem',
    },
  },
  value: {
    fontFamily: '"Roboto Mono", monospace',
    fontWeight: '700',
    fontSize: '0.9rem',
  },
  eraBar: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  eraIndicator: {
    width: '16px',
    height: '16px',
    borderRadius: '3px',
    margin: '0 2px',
    transition: 'all 0.3s ease',
  },
  eraActive: {
    backgroundColor: primary,
    boxShadow: `0 0 8px ${primary}`,
  },
  eraInactive: {
    backgroundColor: ({ theme: currentTheme }) =>
      currentTheme === 'light' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 0.2)',
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: ({ theme: currentTheme }) =>
      currentTheme === 'light' ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.1)',
  },
  ribbon: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 200,
    height: 30,
    backgroundColor: 'rgba(0, 191, 191, 0.8)',
    color: 'white',
    textAlign: 'center',
    lineHeight: '30px',
    letterSpacing: '1px',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    fontFamily: '"Roboto Mono", monospace',
    transform: 'rotate(-45deg) translateX(-96px) translateY(62px)',
    transformOrigin: 'top left',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
    zIndex: 0,
  },
  tooltip: {
    fontSize: '0.8rem',
    padding: '8px',
    backgroundColor: ({ theme: currentTheme }) =>
      currentTheme === 'light'
        ? 'rgba(250, 250, 250, 0.85)'
        : 'rgba(30, 30, 30, 0.85)',
    color: ({ theme: currentTheme }) =>
      currentTheme === 'light' ? '#333' : '#eee',
    boxShadow: ({ theme: currentTheme }) =>
      currentTheme === 'light'
        ? '0 2px 6px rgba(0, 0, 0, 0.15), 0 0 4px 2px rgba(0, 191, 191, 0.4), 0 0 8px rgba(0, 191, 191, 0.2)'
        : '0 2px 6px rgba(0, 0, 0, 0.15), 0 0 4px 2px rgba(0, 191, 191, 0.5), 0 0 8px rgba(0, 191, 191, 0.3)',
    border: '1px solid rgba(0, 191, 191, 0.4)',
    borderRadius: '4px',
    maxWidth: 'none',
  },
  tooltipArrow: {
    color: ({ theme: currentTheme }) =>
      currentTheme === 'light'
        ? 'rgba(250, 250, 250, 0.85)'
        : 'rgba(30, 30, 30, 0.85)',
    '&:before': {
      border: '1px solid rgba(0, 191, 191, 0.4)',
      boxShadow: ({ theme: currentTheme }) =>
        currentTheme === 'light'
          ? '0 0 4px 2px rgba(0, 191, 191, 0.3)'
          : '0 0 4px 2px rgba(0, 191, 191, 0.4)',
    },
  },
}));

const ValidatorCard = ({
  loading,
  endpoint,
  stash,
  identity,
  active,
  commission,
  nominators,
  stake,
  lowest,
  selfVsLowest,
  apy,
  payout,
  recentEras,
}) => {
  const { theme } = useContext(ThemeContext);
  const classes = useStyles({ theme });
  const [copyNotification, setCopyNotification] = useState(false);

  const IDENTITY = 'digitalghost.xyz';
  const ENDPOINT = encodeURIComponent(endpoint);
  const URL_NOMINATION = `https://polkadot.js.org/apps/?filter=${IDENTITY}&rpc=${ENDPOINT}#/staking/targets`;
  const URL_STATS = `https://polkadot.js.org/apps/?rpc=${ENDPOINT}#/staking/query/${stash}`;
  const NETWORK = stash.startsWith('1') ? 'polkadot' : 'kusama';
  const URL_EXPLORER = `https://${NETWORK}.subscan.io/account/${stash}`;

  return (
    <Card className={classes.card}>
      {!loading && stake <= lowest && (<div className={classes.ribbon}>Nominate Now!</div>)}
      <div className={classes.header}>
        <div className={classes.stashContainer}>
          <Link
            href={URL_NOMINATION}
            target='_blank'
            className={classes.identity}
          >
            {identity.toUpperCase()}
          </Link>
          <Box className={loading ? classes.statusUnknown : (active ? classes.statusActive : classes.statusWaiting)}>
            <Link
              href={URL_STATS}
              target='_blank'
              style={{ color: 'inherit' }}
            >
              {loading ? 'UNKNOWN' : (active ? 'ACTIVE' : 'WAITING')}
            </Link>
          </Box>
        </div>

        <div className={classes.stashContainer}>
          <Typography className={classes.stash}>
            <Link
              href={URL_EXPLORER}
              target='_blank'
              style={{ color: 'inherit' }}
            >
              {stash}
            </Link>
          </Typography>
          <IconButton
            className={classes.copyButton}
            onClick={() => {
              navigator.clipboard.writeText(stash);
              setCopyNotification(true);
            }}
          >
            <FileCopyIcon style={{ fontSize: '16px' }} />
          </IconButton>
        </div>
      </div>

      <Box className={classes.divider} component='hr' />

      {loading
        ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '243.66px' }}>
            <CircularProgress size={24} thickness={2} />
          </div>
        )
        : (
          <>
            <div className={classes.dataRow}>
              <Typography className={classes.label}>Commission</Typography>
              <Typography className={classes.value}>{commission}</Typography>
            </div>

            <div className={classes.dataRow}>
              <Typography className={classes.label}>
                Stake
                <Tooltip title='validator / lowest stake' placement='right' classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}>
                  <HelpOutlineIcon className={classes.helpIcon} />
                </Tooltip>
              </Typography>
              <Typography className={classes.value}>{selfVsLowest}</Typography>
            </div>

            <div className={classes.dataRow}>
              <Typography className={classes.label}>Nominators</Typography>
              <Typography className={classes.value}>{nominators}</Typography>
            </div>

            <div className={classes.dataRow}>
              <Typography className={classes.label}>Return</Typography>
              <Typography className={classes.value}>{apy}</Typography>
            </div>

            <div className={classes.dataRow}>
              <Typography className={classes.label}>Payout</Typography>
              <Typography className={classes.value}>{payout}</Typography>
            </div>

            <Box className={classes.divider} component='hr' />

            <Typography className={classes.label} style={{ marginBottom: '8px', textAlign: 'center' }}>
              Recent Eras
            </Typography>

            <div className={classes.eraBar}>
              {recentEras.map((era, index) => (
                <Tooltip
                  key={index}
                  title={
                    <Box style={{ padding: '4px' }}>
                      <Typography variant="caption" display="block">Era: {era.era}</Typography>
                      <Typography variant="caption" display="block">Stake: {era.stake}</Typography>
                      <Typography variant="caption" display="block">Nominators: {era.nominators}</Typography>
                    </Box>
                  }
                  placement="top"
                  arrow
                  classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }}
                >
                  <div
                    className={`${classes.eraIndicator} ${
                      era.active ? classes.eraActive : classes.eraInactive
                    }`}
                  />
                </Tooltip>
              ))}
            </div>
          </>
        )}

      <Snackbar
        className={classes.snackbar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={copyNotification}
        autoHideDuration={1000}
        onClose={() => setCopyNotification(false)}
        message='stash address copied'
      />
    </Card>
  );
};

export default ValidatorCard;
